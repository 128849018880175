import React from "react"
import Layout from "../components/Layout"
import {
  Flex,
  Box,
  Divider,
  Heading,
  Icon,
  Text,
  PseudoBox,
  Collapse,
  Button,
} from "@chakra-ui/core"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import { TiHome } from "react-icons/ti"
import { MdCamera } from "react-icons/md"
import { FaLinkedin, FaInstagram } from "react-icons/fa"
import "react-responsive-carousel/lib/styles/carousel.min.css";

function CollapseDetail({ children }) {
  const [show, setShow] = React.useState(false)

  const handleToggle = () => setShow(!show)

  return (
    <>
      <Collapse isOpen={show}>{children}</Collapse>
      <Button
        backgroundColor="#F8F8F8"
        color="grey"
        width="100%"
        size="sm"
        onClick={handleToggle}
        mt="0.7rem"
      >
        <Flex justify="space-between" width="100%">
          <Text>Show {show ? "Less" : "More"}</Text>
          <Icon name={show ? "chevron-up" : "chevron-down"} />
        </Flex>
      </Button>
    </>
  )
}

const ProfileCard = ({ data }) => (
  <Box
    width={["100%", "100%", "35%", "35%"]}
    border="solid 1px #eee"
    boxShadow="0px 1px 4px rgba(0, 0, 0, 0.08)"
    rounded="lg"
    height="auto"
    alignSelf="flex-start"
    position="sticky"
    top="0"
    roundedTop="lg"
    overflow="hidden"
  >
    <Img fluid={data.file.childImageSharp.fluid} />
    <Box p="1em">
      <Flex align="center" paddingY="4px">
        <Icon color="grey" name="email" fontSize="18px" />
        <PseudoBox _hover={{ textDecoration: "underline" }}>
          <a href="mailto:sereymorm@gmail.com" style={{ paddingLeft: "8px" }}>
            sereymorm@gmail.com
          </a>
        </PseudoBox>
      </Flex>
      <Flex align="center" paddingY="4px">
        <Box as={FaLinkedin} size="18px" color="grey" />
        <PseudoBox _hover={{ textDecoration: "underline" }}>
          <a
            href="https://www.linkedin.com/in/sereymorm/"
            target="_blank"
            style={{ paddingLeft: "8px" }}
          >
            sereymorm on LinkedIN
          </a>
        </PseudoBox>
      </Flex>
      <Flex align="center" paddingY="4px">
        <Box as={MdCamera} size="18px" color="grey" />
        <PseudoBox _hover={{ textDecoration: "underline" }}>
          <a
            href="http://vsco.co/sereymorm/"
            target="_blank"
            style={{ paddingLeft: "8px" }}
          >
            sereymorm on vsco
          </a>
        </PseudoBox>
      </Flex>
      <Flex align="center" paddingY="4px">
        <Box as={FaInstagram} size="18px" color="grey" />
        <PseudoBox _hover={{ textDecoration: "underline" }}>
          <a
            href="http://instagram.com/sereymorm/"
            target="_blank"
            style={{ paddingLeft: "8px" }}
          >
            @sereymorm on Instagram
          </a>
        </PseudoBox>
      </Flex>
    </Box>
  </Box>
)

export default ({ data }) => {
  return (
    <Layout>
      <Box
        display={{ md: "flex", sm: "block" }}
        justifyContent="space-between"
        height="auto"
      >
        <ProfileCard data={data} />
        <Box
          width={[
            "100%", // base
            "100%", // 480px upwards
            "60%", // 768px upwards
          ]}
        >
          <Box height="1em" display={{ md: "none", sm: "block" }} />
          <Heading>Hi, I'm Serey —</Heading>
          <Flex align="center">
            <Box as={TiHome} size="18px" color="lightgrey" />
            <Text marginLeft="14px" color="grey">
              Living in Boston, MA
            </Text>
          </Flex>
          <Heading fontSize="64px" color="lightgrey" p={0} paddingTop="6px">
            “
          </Heading>

          <Text marginTop="-32px">
            I'm a Senior Software Engineer at Wayfair working on a platform team. I
            LOVE working with frontend, web, and cloud technology!
          </Text>
          <Box paddingY="1.2rem">
            <Heading size="sm">Fun Hobbies!</Heading>

            <Box paddingTop="4px">
              <Text>
                🥧 Cooking &amp; Baking (psst! — check out my{" "}
                <Link to="/blog" style={{ textDecoration: "underline" }}>
                  blog
                </Link>
                )
              </Text>
            </Box>
            <Box paddingTop="4px">
              <Text>
                📸 Photography (
                <a
                  href="http://vsco.co/sereymorm/"
                  target="_blank"
                  display="inline-block"
                  style={{ textDecoration: "underline" }}
                >
                  VSCO
                </a>{" "}
                &amp;{" "}
                <a
                  href="http://instagram.com/sereymorm/"
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Instagram
                </a>
                )
              </Text>
            </Box>
            <Box paddingTop="4px">
              <Text>
                ✏️ Writing newsletters about things I'm currently into @ {' '}
                <a
                  href="https://perfectlyimperfect.substack.com/"
                  target="_blank"
                  display="inline-block"
                  style={{ textDecoration: "underline" }}
                >
                  Perfectly Imperfect
                </a>
              </Text>
            </Box>
            <Box paddingTop="4px">
              <Text>🌱 Plants</Text>
            </Box>
            <Box paddingTop="4px">
              <Text>📐 Browsing Designs(from water bottles to interior)</Text>
            </Box>
          </Box>
          <Divider />
          <Flex>
            <Heading size="md">Currently&ensp;·</Heading>
            <Heading size="md" color="#7F187F">
              &ensp;
              <a
                href="https://www.wayfair.com/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                Wayfair
              </a>
            </Heading>
          </Flex>

          <Text paddingTop="10px" color="dimgrey">
            Wayfair Inc. is an American e-commerce company that sells furniture
            and home-goods.
          </Text>

          <Text fontWeight="700" color="grey" paddingTop="12px">
            Senior Software Engineer
          </Text>
          <Text fontWeight="700" color="darkgrey">
            Aug 2020 — Present
          </Text>
          <Text paddingTop="4px">
            Enabling and guiding teams to deliver a product suite to the cloud.
          </Text>

          <Text fontWeight="700" color="grey" paddingTop="12px">
            Software Engineer II
          </Text>
          <Text fontWeight="700" color="darkgrey">
            Jul 2019 – Aug 2020  · 1 yr 1 mo
          </Text>

          <Text fontWeight="700" color="grey" paddingTop="12px">
            Software Engineer I
          </Text>
          <Text fontWeight="700" color="darkgrey">
            Jun 2018 – Jul 2019 · 1 yr 2 mos
          </Text>
          <CollapseDetail>
            <Text paddingTop="4px">
              · Advocated and led initiative for GraphQL; minimized time for
              feature development in all new tools in org
            </Text>
            <Text paddingTop="4px">
              · Reviewed JavaScript code as a certified reviewer for a team of
              ~120
            </Text>
            <Text paddingTop="4px">
              · Helped plan multi-phase technical designs
            </Text>
          </CollapseDetail>

          <Divider />
          <Flex>
            <Heading size="md">Previously&ensp;·</Heading>
            <Heading size="md" color="#A0C957">
              &ensp;
              <a
                href="https://www.ifscore.com/"
                target="_blank"
                style={{ display: "inline-block" }}
              >
                IFS Core
              </a>
            </Heading>
          </Flex>
          <Text fontWeight="700" color="grey" paddingTop="12px">
            Intern
          </Text>
          <Text fontWeight="700" color="darkgrey">
            May 2017 – Dec 2017 · 7 mos
          </Text>
          <Text paddingTop="10px" color="dimgrey">
            Connect and empower specialty contractor and construction
            professionals.
          </Text>
          <CollapseDetail>
            <Text paddingTop="4px">
              · Containerized applications for cloud migration, to minimize
              downtime during updates and improve network traffic during peak
              hours
            </Text>
            <Text paddingTop="4px">
              · Designed/implemented fully automated CI/CD pipeline with Jenkins
              and AWS
            </Text>
            <Text paddingTop="4px">
              · Implemented workflow/scripts to speed up the process of setting
              up AWS development environments for newcomers
            </Text>
            <Text color="dimgrey" paddingTop="8px">
              Docker · Kubernetes · AWS · Jenkins · Node
            </Text>
          </CollapseDetail>
        </Box>
      </Box>
      <Box height="100px" />
    </Layout>
  )
}
export const query = graphql`
  query {
    file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 640, maxHeight: 280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
